import {React, StatePool, useEffect, useState} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import {useData} from "../../../Context/ContextApp";
import IconStar from '../images/icon_star_blue.svg';
import IconStarGray from '../images/icon_star_gray.svg';
import SectionFooter from './SectionFooter';
import NameReviewer from '../FilePreviewAdditional/review_name-reviewers.json';

const LangReview = StatePool.createState('');

const SectionReview = () => {
    const {data} = useData();
    const [reviewItems, setReviewItems] = useState([]);
    const [langReview, setLangReview] = StatePool.useState(LangReview);
    const handleLangReview = (langCode) => setLangReview(langCode);

    useEffect(() => {
        let codeLangReview = data['langReviewSelect'];
        handleLangReview(codeLangReview);

        console.log(data)
    }, [data]);

    const AddReview = () => {
        let DataReview = data.templateReview;
        let name = [
            'Robert Kofreit',
            'Julia Sunak',
            'Ihor Sinuk',
            'Andrew Bashkov',
            'Alex Bordyuk'
        ];
        let reviews = DataReview.map((text, index) => (
            <div key={index} className={PWAPreviewStyle.comment}>
                <div className={PWAPreviewStyle.comment__top}>
                    <p className={`${PWAPreviewStyle.comment__icon} ${PWAPreviewStyle.comment__icon_1}`}></p>
                    <p className={PWAPreviewStyle.comment__title}>{name[index]}</p>
                </div>
                <div className={PWAPreviewStyle.comment__stars}>
                    <div className={PWAPreviewStyle.stars}>
                        {Array.from({ length: 4 }, (_, i) => (
                            <img key={i} className={PWAPreviewStyle.star__img} src={IconStar} alt="" />
                        ))}
                        {Array.from({ length: 1 }, (_, i) => (
                            <img key={i} className={PWAPreviewStyle.star__img} src={IconStarGray} alt="" />
                        ))}
                    </div>
                    <p className={PWAPreviewStyle.stars__content}>{text.date}</p>
                </div>
                <div className={PWAPreviewStyle.comment__content}>
                    <p>{text}</p>
                </div>
                <SectionFooter />
            </div>
        ));

        setReviewItems(reviews);
    }

    useEffect(() => {
        AddReview();
    }, [data]);

    return (
        <section className={`${PWAPreviewStyle.reviews_container} ${PWAPreviewStyle.comments}`}>
            {reviewItems}
        </section>
    );
};

export default SectionReview;
