import {React, $} from "../../../imports/imports-packege";
import PWAPreviewStyle from './PWAPreviewStyle.module.css';
import SectionScrollBanner from "./Sections/SectionScrollBanner";
import SectionTop from "./Sections/SectionTop";
import SectionDescription from "./Sections/SectionDescription";
import SectionDataSecurity from "./Sections/SectionDataSecurity";
import SectionRating from "./Sections/SectionReviewRating";
import SectionPopupBanner from "./Sections/SectionPopupBanner";
import SectionFooter from "./Sections/SectionFooter";
import SectionReview from "./Sections/SectionReview";

const PWAPreview = () => {
    return (
        <div className={PWAPreviewStyle.pwa_container}>
            <SectionTop />
            <SectionScrollBanner />
            <SectionDescription />
            <SectionDataSecurity />
            <SectionRating />
            <SectionReview />
            <SectionPopupBanner />
            {/*<SectionFooter />*/}
        </div>
    );
};

export default PWAPreview;