import {React} from '../../imports/imports-packege';
import HeaderStyle from './HeaderStyle.module.scss';
import {MainLogo} from '../../imports/imports-images'
import LanguagesSelect from './LanguagesSelect';

const Header = () => {

    return (
        <div className={HeaderStyle.container}>
            <div className={HeaderStyle.LogoNavBlock}>
                <div className={HeaderStyle.LogoContainer}>
                    <img src={MainLogo} alt="" className={HeaderStyle.LogoImage}/>
                </div>
            </div>

            <LanguagesSelect />
        </div>
    );
};

export default Header;