import {React} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import StarBlue from '../images/icon_star_blue.png';
import StarGray from '../images/icon_star_gray.png';
import RectangleRatingLine5 from '../images/rect_blue_5.png';
import RectangleRatingLine4 from '../images/rect_blue_4.png';
import RectangleRatingLine3 from '../images/rect_blue_3.png';
import RectangleRatingLine2 from '../images/rect_blue_2.png';
import RectangleRatingLine1 from '../images/rect_blue_1.png';
import { useData } from "../../../Context/ContextApp";
import TranslateText from '../Translate/text-translate.json';

const SectionRating = () => {
    let {data, updateData} = useData();
    const ListTextSecurity = TranslateText[data.langCode];

    return (
        <section className={PWAPreviewStyle.rewievs}>
            <h1>
                {data.langCode.length > 0 ? ListTextSecurity['title_review']: 'Valutazioni e recensioni' }
            </h1>
            <div className={PWAPreviewStyle.rating}>
                <p className={PWAPreviewStyle.rating__left}>4,8</p>

                <div className={PWAPreviewStyle.rating__right}>
                    <div className={PWAPreviewStyle.rating__item}>
                        <p className={PWAPreviewStyle.item__number}>5</p>
                        <div className={PWAPreviewStyle.item__img}>
                            <img src={RectangleRatingLine5} alt=""/>
                        </div>
                    </div>

                    <div className={PWAPreviewStyle.rating__item}>
                        <p className={PWAPreviewStyle.item__number}>4</p>
                        <div className={PWAPreviewStyle.item__img}>
                            <img src={RectangleRatingLine4} alt=""/>
                        </div>
                    </div>
                    <div className={PWAPreviewStyle.rating__item}>
                        <p className={PWAPreviewStyle.item__number}>3</p>
                        <div className={PWAPreviewStyle.item__img}>
                            <img src={RectangleRatingLine3} alt=""/>
                        </div>
                    </div>
                    <div className={PWAPreviewStyle.rating__item} >
                        <p className={PWAPreviewStyle.item__number} >2</p>
                        <div className={PWAPreviewStyle.item__img} >
                            <img src={RectangleRatingLine2} alt=""/>
                        </div>
                    </div>
                    <div className={PWAPreviewStyle.rating__item}>
                        <p className={PWAPreviewStyle.item__number}>1</p>
                        <div className={PWAPreviewStyle.item__img}>
                            <img src={RectangleRatingLine1} alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={PWAPreviewStyle.rating__stars}>
                <div className={PWAPreviewStyle.stars}>
                    <img className={PWAPreviewStyle.star__img} src={StarBlue} alt=""/>
                    <img className={PWAPreviewStyle.star__img} src={StarBlue} alt=""/>
                    <img className={PWAPreviewStyle.star__img} src={StarBlue} alt=""/>
                    <img className={PWAPreviewStyle.star__img} src={StarBlue} alt=""/>
                    <img className={PWAPreviewStyle.star__img} src={StarGray} alt=""/>
                </div>
                <p className={PWAPreviewStyle.stars__content}>2381</p>
            </div>
        </section>
    );
};

export default SectionRating;