const AppListDevelopers = [
    {value: 'PlayDev', label: 'Play Dev'},
    {value: 'GoogleDev', label: 'Google Dev'},
    {value: 'AviatorDev', label: 'Aviator Dev'},
    {value: 'ZeusDev', label: 'Zeus Dev'},
    {value: 'PlinkoDev', label: 'Plinko Dev'},
    {value: 'BonanzaDev', label: 'Bonanza Dev'}
];
const AppListCategories = [
    {value: 'casino', label: 'Casino'},
    {value: 'arcade', label: 'Arcade'},
    {value: 'sport', label: 'Sport'},
    {value: 'games', label: 'Games'}
];
const AppListUserAge = [
    {value: '12', label: '+12'},
    {value: '14', label: '+14'},
    {value: '16', label: '+16'},
    {value: '18', label: '+18'},
    {value: '21', label: '+21'}
];
const AppListSize = [
    {value: '1', label: '1'},
    {value: '3', label: '3'},
    {value: '6', label: '6'},
    {value: '9', label: '9'},
    {value: '12', label: '12'},
    {value: '15', label: '15'},
];
const AppListInstall = [
    {value: '1000', label: '1000'},
    {value: '10000', label: '10000'},
    {value: '50000', label: '50000'},
    {value: '100000', label: '100000'},
];
const AppListButtonText = [
    {value: 'play', label: 'Play'},
    {value: 'install', label: 'Install'},
    {value: 'open', label: 'Open'}
];
const ListOptionLanguageReview = [
    {value: 'en', label: 'Англійська'},
    {value: 'ukr', label: 'Українська'},
    {value: 'tur', label: 'Турецька'},
    {value: 'swe', label: 'Швецька'},
    {value: 'es', label: 'Іспанська'},
    {value: 'slo', label: 'Словацька'},
    {value: 'rus', label: 'Свинособацька'},
    {value: 'rum', label: 'Руминська'},
    {value: 'por', label: 'Португальська'},
    {value: 'pol', label: 'Польська'},
    {value: 'nor', label: 'Норвезька'},
    {value: 'lit', label: 'Литовська'},
    {value: 'kor', label: 'Корейська'},
    {value: 'jpn', label: 'Японська'},
    {value: 'hin', label: 'Хінді'},
    {value: 'bg', label: 'Болгарська'},
    {value: 'zh', label: 'Китайська'},
    {value: 'cs', label: 'Чеська'},
    {value: 'da', label: 'Дацька'},
    {value: 'nl', label: 'Нідерландська'},
    {value: 'et', label: 'Естонська'},
    {value: 'fi', label: 'Фінська'},
    {value: 'fr', label: 'Французька'},
    {value: 'de', label: 'Німецька'},
    {value: 'el', label: 'Грецька'},
    {value: 'hu', label: 'Угорська'},
    {value: 'id', label: 'Індонезійська'},
    {value: 'bn', label: 'Бенгальська'},
    {value: 'it', label: 'Італійська'},
    {value: 'slv', label: 'Словенська'},
    {value: 'lav', label: 'Латвійська'},
];

const LanguagePwaList = [
    {value: 'en', label: 'Англійська'},
    {value: 'ukr', label: 'Українська'},
    {value: 'tur', label: 'Турецька'},
    {value: 'swe', label: 'Швецька'},
    {value: 'spa', label: 'Іспанська'},
    {value: 'slo', label: 'Словацька'},
    {value: 'rus', label: 'Свинособацька'},
    {value: 'rum', label: 'Руминська'},
    {value: 'por', label: 'Португальська'},
    {value: 'pol', label: 'Польська'},
    {value: 'nor', label: 'Норвезька'},
    {value: 'lit', label: 'Литовська'},
    {value: 'kor', label: 'Корейська'},
    {value: 'jpn', label: 'Японська'},
    {value: 'hin', label: 'Хінді'},
    {value: 'bg', label: 'Болгарська'},
    {value: 'zh', label: 'Китайська'},
    {value: 'cs', label: 'Чеська'},
    {value: 'da', label: 'Дацька'},
    {value: 'nl', label: 'Нідерландська'},
    {value: 'et', label: 'Естонська'},
    {value: 'fi', label: 'Фінська'},
    {value: 'fr', label: 'Французька'},
    {value: 'de', label: 'Німецька'},
    {value: 'el', label: 'Грецька'},
    {value: 'hu', label: 'Угорська'},
    {value: 'id', label: 'Індонезійська'},
    {value: 'bn', label: 'Бенгальська'},
    {value: 'it', label: 'Італійська'},
    {value: 'slv', label: 'Словенська'},
    {value: 'lav', label: 'Латвійська'},
];

const CountReviews = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'},
    {value: '6', label: '6'},
    {value: '7', label: '7'},
    {value: '8', label: '8'},
    {value: '9', label: '9'},
    {value: '10', label: '10'},
];

export {
    AppListDevelopers,
    AppListCategories,
    AppListUserAge,
    AppListSize,
    AppListInstall,
    AppListButtonText,
    ListOptionLanguageReview,
    LanguagePwaList,
    CountReviews
}