import './assets/style/AppStyle.css';
import {MainContentSection, Header} from "./imports/imports-components";

function App() {
  return (
    <div className="MainFullScreen">
        <Header />
        <MainContentSection />
    </div>
  );
}

export default App;
