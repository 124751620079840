import {React, useState, useCallback} from "../../../imports/imports-packege";
import CheckBoxStyle from "./CheckBoxStyle.module.scss";

const CheckBox = ({statusCheck, getSaveStatus}) => {
    const [isChecked, setIsChecked] = useState();
    const handleChange = useCallback((event) => {
        setIsChecked(event.target.checked);
        statusCheck(event.target.checked);
    }, [statusCheck]);


    return (
        <div className={CheckBoxStyle.CustomCheckbox}>
            <input
                type="checkbox"
                className={CheckBoxStyle.CustomCheckbox_input}
                id="CustomCheckbox"
                onClick={handleChange}
                checked={getSaveStatus}
            />
            <label
                htmlFor="CustomCheckbox" >
            </label>
        </div>
    );
};

export default CheckBox;