import {React, StatePool, useEffect, useState} from "../../../imports/imports-packege";
import AppParametersStyle from "./AppParametersStyle.module.scss";
import {Input, ActionTitle, SelectList, TextAria} from "../../../imports/imports-components";
import {
    AppListCategories,
    AppListUserAge,
} from "../../SelectOption/SelectOption";
import { useData } from "../../Context/ContextApp";

let AppDeveloperName = StatePool.createState('');
let SizeApp = StatePool.createState('');
let CountInstalled = StatePool.createState('');
let CountComments = StatePool.createState('');
let UserAgeSelected = StatePool.createState('');
let DescriptionTitle = StatePool.createState('');
let Description = StatePool.createState('');

const AppParameters = () => {
    let {data, updateData} = useData();

    const [authorApp, setAuthorApp] = StatePool.useState(AppDeveloperName);
    const handleAuthorApp = (author) => {
        setAuthorApp(author);
        updateData({ ...data, author:  author});
    }


    const [categoryApp, setCategoryApp] = useState(data.categories || '');
    useEffect(() => {
        updateData({ ...data, categories: categoryApp });
    }, [categoryApp]);
    const handleSelectTranslate = (categories) => {
        setCategoryApp(categories);
    }

    const [userAge, setUserAge] = useState(data.age || '');
    useEffect(() => {
        updateData({ ...data, age: userAge });
    }, [userAge]);
    const handleUserAge = (translate) => {
        setUserAge(translate);
    }

    const [appSize, setAppSize] = StatePool.useState(SizeApp);
    const handleAppSize = (size) => {
        setAppSize(size);
        updateData({ ...data, appSize:  size});
    }
    const [installCount, setInstallCount] = StatePool.useState(CountInstalled);
    const handleInstallCount = (count) => {
        setInstallCount(count);
        updateData({ ...data, amountInstall:  count});
    }
    const [landDescriptionTitle, setLandDescriptionTitle] = StatePool.useState(DescriptionTitle)
    const handleDescriptionTitle = (descriptionTitle) => {
        setLandDescriptionTitle(descriptionTitle);
        updateData({ ...data, landDescriptionTitle:  descriptionTitle});
    }
    const [countComments, setCountComments] = StatePool.useState(CountComments);
    const handleCountComments = (count) => {
        setCountComments(count);
        updateData({ ...data, countComments:  count});
    }
    const [landDescription, setLandDescription] = StatePool.useState(Description)
    const handleDescription = (description) => {
        setLandDescription(description);
        updateData({ ...data, landDescriptionText:  description});
    }

    return (
        <div className={AppParametersStyle.TabContent}>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={"Автор"}/>
                <Input
                    placeholder={"Play Dev"}
                    inputValue={handleAuthorApp}
                    valueSave={authorApp} />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={"Категорія"}/>
                <SelectList
                    optionList={AppListCategories}
                    getSelectValue={handleSelectTranslate}
                    value={categoryApp}
                />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={"Кількість коментарів"}/>
                <Input
                    placeholder={"1034"}
                    inputValue={handleCountComments}
                    valueSave={countComments} />
            </div>
            <div className={`${AppParametersStyle.ParametersItem} ${AppParametersStyle.DoubleSelect}`}>
                <div className={AppParametersStyle.List}>
                    <ActionTitle actionText={"Вік"}/>
                    <SelectList
                        optionList={AppListUserAge}
                        getSelectValue={handleUserAge}
                        value={userAge}
                    />
                </div>
                <div className={AppParametersStyle.List}>
                    <ActionTitle actionText={"Розмір (мб)"}/>
                    <Input
                        placeholder={"6"}
                        inputValue={handleAppSize}
                        valueSave={appSize}
                    />
                </div>
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={"Кількість установок"}/>
                <Input
                    placeholder={"2034"}
                    inputValue={handleInstallCount}
                    valueSave={installCount} />
            </div>
            {/*<div className={AppParametersStyle.ParametersItem}>*/}
            {/*    <ActionTitle actionText={"Текст на кнопці"}/>*/}
            {/*    <SelectList*/}
            {/*        optionList={AppListButtonText}*/}
            {/*        getSelectValue={handleButtonText}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={"Заголовок опису"}/>
                <Input
                    placeholder={"Заголовок"}
                    inputValue={handleDescriptionTitle}
                    valueSave={landDescriptionTitle}
                />
            </div>
            <div className={AppParametersStyle.ParametersItem}>
                <ActionTitle actionText={"Опис"}/>
                <TextAria
                    placeholderText="Ваш опис"
                    valueField={handleDescription}
                    valueSave={landDescription} />
            </div>
        </div>
    );
};

export default AppParameters;