import {React, useState, useEffect, StatePool} from "../../../imports/imports-packege";
import AppReviewStyle from "./AppReviewStyle.module.scss";
import {ActionTitle, SelectList, CheckBox, PopoverInfo} from "../../../imports/imports-components";
import {ListOptionLanguageReview} from "../../SelectOption/SelectOption";
import {useData} from "../../Context/ContextApp";
import {useCallback} from "react";
import TemplateReviewText from "./review_template.json";

let TemplateReviewStatus = StatePool.createState(true);
let LanguageSelectReview = StatePool.createState('');
let CustomReviewList = StatePool.createState([]);

const AppReview = () => {
    let {data, updateData} = useData();
    const [radioChecking, setRadioChecking] = useState(data.ReviewCountSelect || "");
    const [languageTemplateReview, setLanguageTemplateReview] = StatePool.useState(LanguageSelectReview);
    const [checkBoxStatus, setCheckBoxStatus] = StatePool.useState(TemplateReviewStatus)

    const handleCustomReviewStatus = useCallback((e) => {
        setCheckBoxStatus(e);
    }, []);

    useEffect(() => {
        updateData({...data, TemplateReviewStatus: checkBoxStatus})
    }, [checkBoxStatus])

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value;
        setRadioChecking(selectedValue);
        updateData({ ...data, ReviewCountSelect: selectedValue })
    };

    const handleLanguageReview = (translate) => {
        setLanguageTemplateReview(translate);
    };

    useEffect(() => {
        console.log(languageTemplateReview)
        updateData((prevData) => ({
            ...prevData,
            langReviewSelect: languageTemplateReview,
            ReviewCountSelect: radioChecking,
            TemplateReviewStatus: checkBoxStatus
        }));
    }, [languageTemplateReview, radioChecking, checkBoxStatus]);

    /*Review template*/
    const [messageShow, setMessageShow] = useState([]);
    const setReview = () => {
        let templateReviews = [];
        let count = 1;

        for (let el in TemplateReviewText[languageTemplateReview]) {
            if (count <= radioChecking) {
                templateReviews.push(TemplateReviewText[languageTemplateReview][el]);
                count++;
            } else {
                break;
            }
        }
        updateData({...data, templateReview: templateReviews });
        setMessageShow(templateReviews);
    };

    useEffect(() => {
        setReview();
    }, [radioChecking, languageTemplateReview]);

    /*Review custom*/

    const [comments, setComments] = useState([]);
    const handleCommentChange = (index, value) => {
        setComments(prevComments => {
            const newComments = [...prevComments];
            newComments[index] = value;
            updateData({ ...data, templateReview: newComments });
            return newComments;
        });
    };

    return (
        <div className={AppReviewStyle.TabContent}>
            <div className={AppReviewStyle.CountReview}>
                <ActionTitle actionText={"К-ть коментарів"} />
                <div className={AppReviewStyle.CountReview_ListRadio}>
                    <div className={AppReviewStyle.CountReview_Radio}>
                        <label>
                            <input
                                type="radio"
                                value="1"
                                name="count-review"
                                onClick={handleRadioChange}
                                checked={radioChecking === '1'}
                            />
                            1
                        </label>
                    </div>
                    <div className={AppReviewStyle.CountReview_Radio}>
                        <label>
                            <input
                                type="radio"
                                value="2"
                                name="count-review"
                                onClick={handleRadioChange}
                                checked={radioChecking === '2'}
                            />
                            2
                        </label>
                    </div>
                    <div className={AppReviewStyle.CountReview_Radio}>
                        <label>
                            <input
                                type="radio"
                                value="3"
                                name="count-review"
                                onClick={handleRadioChange}
                                checked={radioChecking === '3'}
                            />
                            3
                        </label>
                    </div>
                    <div className={AppReviewStyle.CountReview_Radio}>
                        <label>
                            <input
                                type="radio"
                                value="4"
                                name="count-review"
                                onClick={handleRadioChange}
                                checked={radioChecking === '4'}
                            />
                            4
                        </label>
                    </div>
                    <div className={AppReviewStyle.CountReview_Radio}>
                        <label>
                            <input
                                type="radio"
                                value="5"
                                name="count-review"
                                onClick={handleRadioChange}
                                checked={radioChecking === '5'}
                            />
                            5
                        </label>
                    </div>
                </div>
            </div>
            <div className={AppReviewStyle.TemplateReviews}>
                <div className={AppReviewStyle.TemplateReviews_CheckboxTemplate}>
                    <CheckBox statusCheck={handleCustomReviewStatus} getSaveStatus={checkBoxStatus} />
                    <p className={AppReviewStyle.TemplateReviews_CheckboxTemplate_Text}>
                        Шаблонні коменарі
                    </p>
                </div>
                {checkBoxStatus && (
                    <div className={AppReviewStyle.TemplateReviews_Languages}>
                        <PopoverInfo
                            TitleText={"Мова"}
                            DescriptionText={"При виборі шаблонної мови, коментарі підтягуються автоматично. Для додавання власних коментарів, поле потрібно лишити пустим"} />
                        <SelectList
                            optionList={ListOptionLanguageReview}
                            getSelectValue={handleLanguageReview}
                            value={languageTemplateReview}
                        />
                    </div>
                )}
            </div>
            <div className={AppReviewStyle.ReviewsBlock}>
                {checkBoxStatus ? (
                    <div>
                        <div>
                            {messageShow.map((message, index) => (
                                <div key={index} className={AppReviewStyle.ReviewBlockItem}>
                                    <label>{`Коментар ${index + 1}:`}</label>
                                    <p className={AppReviewStyle.ReviewBlockItem_TextTemplateReview}>
                                        {message}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    !checkBoxStatus && (
                        <>
                            {
                                Array.from({ length: radioChecking }).map((_, num) => (
                                    <div key={num} className={AppReviewStyle.ReviewBlockItem}>
                                        <label>{`Коментар ${num + 1}:`}</label>
                                        <input
                                            placeholder="Ваш коментар"
                                            value={data.templateReview[num]}
                                            className={AppReviewStyle.InputField}
                                            onChange={(e) => handleCommentChange(num, e.target.value)}
                                        />
                                    </div>
                                ))
                            }
                        </>
                    )
                )}
            </div>

        </div>
    );
};

export default AppReview;