import {React, StatePool, useEffect, axios, queryString, useState} from "../../imports/imports-packege";
import {useData} from "../Context/ContextApp";
import PreviewStyle from "./PreviewStyle.module.scss";
import PWAPreview from "./PWAPreview/PWApreview";
import ErrorPopup from '../ErrorHandle/ErrorHandle';
import App from "../../App";

const OneSignalId = StatePool.createState('');
const KeitaroId = StatePool.createState('');
const ButtonText = StatePool.createState('Надіслати');
const AppName = StatePool.createState('');

const Preview = () => {
    const { data, updateData } = useData();
    const [errorText, setErrorText] = useState();
    const handleErrorText = (error) => setErrorText(error);

    const [landName, setLandName] = StatePool.useState(AppName);
    const [oneSignalId, setOneSignalId] = StatePool.useState(OneSignalId);
    const [keitaroId, setKeitaroId] = StatePool.useState(KeitaroId);
    const [buttonText, setButtonText] = StatePool.useState(ButtonText);

    const handleLandName = (name) => setLandName(name);
    const handleButtonText = (text) => setButtonText(text);
    const handlerOneSignalId = (query_appId) => setOneSignalId(query_appId);
    const handlerKeitaroId = (query_keitaroId) => setKeitaroId(query_keitaroId);

    const currentUrl = window.location.href;
    const parsedParams = queryString.parseUrl(currentUrl);

    const keitaro_id = parsedParams.query.keitaro_id;
    const onesignal_id = parsedParams.query.onesignal_id;

    const [responseMessage, setResponseMessage] = useState(undefined);
    const handleResponseMessage = (message) => setResponseMessage(message)
    useEffect(() => {
        if (responseMessage === 'success') {
            window.location.href = 'tg://resolve?domain=easy_links_bot'
        }
        if (responseMessage === 'error') {
            alert('Помилка при відправці в кейтаро \b Перейдіть заново по посиланню в ТГ ');
        }
    }, [handleResponseMessage]);

    useEffect(() => {
        if (keitaro_id) {
            handlerKeitaroId(keitaro_id);
        }
        if (onesignal_id) {
            handlerOneSignalId(onesignal_id);
        }

        updateData({
            ...data,
            oneSignalId: oneSignalId,
            keitaroId: keitaroId
        })
    }, [oneSignalId, keitaroId]);

    useEffect(() => {
        handleLandName(data.landName);
    }, [data]);

    const ArrayImagesSreen = [];

    useEffect(() => {
        if (data.screenListFirst) {
            ArrayImagesSreen.push(data.screenListFirst);
        }
        if (data.screenListSecond) {
            ArrayImagesSreen.push(data.screenListSecond);
        }
        if (data.screenListThird) {
            ArrayImagesSreen.push(data.screenListThird);
        }
        if (data.screenListFour) {
            ArrayImagesSreen.push(data.screenListFour);
        }
    }, [data]);

    function SendDataPwa() {
        const sendData = async () => {
            try {
                handleButtonText('Відправлення...');
                const url = 'https://pwa-maker.com/api/create-pwa';
                // const url = 'http://localhost:8282/api/create-pwa';
                const formData = new FormData();

                formData.append('keitaro_id', data.keitaroId);
                formData.append('oneSignalId', data.oneSignalId);

                if (!data.langCode) {
                    handleErrorText('Виберіть мову  ПВА');
                    return false;
                }
                formData.append('langCode', data.langCode);
                formData.append('landName', data.landName);
                if (!data.iconFile) {
                    handleErrorText('Виберіть іконку');
                    return false;
                }
                formData.append('iconFile', data.iconFile);
                if (!data.author) {
                    handleErrorText('Впишіть розробника');
                    return false;
                }
                formData.append('author', data.author);
                if (!data.countComments){
                    handleErrorText('Впишіть кількість коментарів');
                    return false;
                }
                formData.append('countComments', data.countComments);
                if (!data.categories) {
                    handleErrorText('Виберіть категорію');
                    return false;
                }
                formData.append('categories', data.categories);
                if (!data.age) {
                    handleErrorText('Виберіть вік');
                    return false;
                }
                formData.append('age', data.age);
                if (!data.appSize) {
                    handleErrorText('Впишіть розмір ПВА');
                    return false;
                }
                formData.append('appSize', data.appSize);
                if (!data.amountInstall) {
                    handleErrorText('Впишіть кількість скачувать');
                    return false;
                }
                formData.append('amountInstall', data.amountInstall);
                if (!data.landDescriptionTitle) {
                    handleErrorText('Напишіть текст заголовку');
                    return false;
                }
                formData.append('landDescriptionTitle', data.landDescriptionTitle);
                if (!data.landDescriptionText) {
                    handleErrorText('Напишіть текст опису');
                    return false;
                }
                formData.append('landDescriptionText', data.landDescriptionText);

                if (!data.parseContents) {
                    ArrayImagesSreen.forEach((file, index) => {
                        formData.append('listScreenBanner', file);
                    });
                    formData.append('parseFile', false);
                } else {
                    formData.append('parseFile', true);
                }

                if (data.templateReview.length < 1) {
                    handleErrorText('Додайте коментарі');
                    return false;
                }
                data.templateReview.forEach((text, index) => {
                    formData.append('review', text);
                });
                const response = await axios.post(url, formData);
                handleResponseMessage(response.data);
            } catch (error) {
                console.log('Error:', error);
            } finally {
                handleButtonText('Надіслати'); // Повертаємо оригінальний текст на кнопці
            }
        };
        sendData();
    }

    return (
        <div className={PreviewStyle.PreviewContainer}>
            <ErrorPopup textError={errorText} />
            <div className={PreviewStyle.HeaderPreview}>
                <div className={PreviewStyle.InfoDownload}>
                    <div className={PreviewStyle.InfoDownload_NameLand}>
                        <p className={PreviewStyle.InfoDownload_NameLand_Text}>
                            {!landName ? 'Назва пріли' : landName}
                        </p>
                    </div>
                    <div className={PreviewStyle.InfoDownload_SendLand} onClick={SendDataPwa}>
                        <div className={PreviewStyle.InfoDownload_SendLand_Button}>
                            {buttonText}
                        </div>
                    </div>
                </div>
            </div>
            <PWAPreview />
        </div>
    );
};

export default Preview;