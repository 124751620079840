import {React, BrowserRouter} from '../../imports/imports-packege';
import MainContentSectionStyle from './MainContentSectionStyle.module.scss';
import {Navigation, Preview} from "../../imports/imports-components";
import {DataProvider} from '../Context/ContextApp';

const MainContentSection = () => {
    return (
        <div className={MainContentSectionStyle.MainSection}>
            <DataProvider>
                <div className={MainContentSectionStyle.MainSection_NavigationEditSection}>
                    <BrowserRouter>
                        <Navigation />
                    </BrowserRouter>
                </div>
                <div className={MainContentSectionStyle.PreviewSection}>
                    <Preview />
                </div>
            </DataProvider>
        </div>
    );
};

export default MainContentSection;