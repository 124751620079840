import {React, useEffect} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import { useData } from "../../../Context/ContextApp";
import TranslateText from '../Translate/text-translate.json';

const SectionFooter = () => {
    let {data, updateData} = useData();
    const ListTextSecurity = TranslateText[data.langCode];
    console.log(ListTextSecurity)
    return (
        <section className={PWAPreviewStyle.footer}>
            <div className={PWAPreviewStyle.footer__question}>
                <p className={PWAPreviewStyle.question}>
                    {data.langCode.length > 0 ? ListTextSecurity['footer_title_question'] : 'Questa informazione ti è stata utile?'}
                </p>
                <div className={PWAPreviewStyle.question__btn}>
                    <div className= {PWAPreviewStyle.btn__yes}>
                        {data.langCode.length > 0 ? ListTextSecurity['footer_answer_yes'] : 'SÌ'}
                    </div>
                    <div className={PWAPreviewStyle.btn__no}>
                        {data.langCode.length > 0 ? ListTextSecurity['footer_answer_no'] : 'NO'}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionFooter;